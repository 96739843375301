<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="layout-top-spacing">
        <div class="mb-3 d-flex justify-content-between">
          <div>
            <h3>Payout Requests</h3>
            <a href="#" @click.prevent="intiateExportRequests">
              Export Payout Requests
            </a>
          </div>

          <b-btn variant="primary" v-b-modal.create-payout-request>
            <i class="fa fa-plus mr-1"></i>
            Make a Request
          </b-btn>
        </div>

        <b-row
          class="bg-white shadow rounded ml-1 mr-1 mb-2"
          v-if="paystackBalance && (isManager() || isAdmin())"
        >
          <b-col class="mb-2">
            <div class="p-3 bg-white">
              <p>Paystack Balance</p>
              <h3>{{ paystackBalance }}</h3>
            </div>
          </b-col>
          <b-col>
            <div class="d-inline float-right bg-white p-3">
              <span>Total Pending</span>
              <h3>{{ totalPending }}</h3>
            </div>
          </b-col>
        </b-row>

        <b-card title="Card Title" no-body>
          <b-card-header
            header-tag="nav"
            header-bg-variant="transparent"
            header-class="pt-4"
          >
            <b-nav card-header tabs justified>
              <b-nav-item
                to="/payout-requests/"
                exact
                exact-active-class="active"
              >
                Pending Payout Request
              </b-nav-item>
              <b-nav-item
                to="/payout-requests/completed"
                exact
                exact-active-class="active"
              >
                Completed Payout Request
              </b-nav-item>
            </b-nav>
          </b-card-header>

          <b-card-body style="padding-top: 30px !important">
            <router-view></router-view>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import roles from "@/mixins/roles";

export default {
  name: "PayoutRequests",
  mixins: [roles],
  data() {
    return {
      paystackBalance: null,
      totalPending: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.isManager() || this.isAdmin()) {
        this.getPaystackBalance();
      }
    }, 1000);

    this.fetchPendingRequests();
  },
  methods: {
    getPaystackBalance() {
      http
        .get(endpoints.GET_PAYSTACK_BALANCE)
        .then((response) => {
          this.paystackBalance = response.data
            .filter((data) => data.currency == "NGN")
            .map((data) => {
              data.balance = data.balance / 100;
              return this.formatMoney(data.balance, data.currency);
            })
            .join(" ⎯⎯⎯ ");
        })
        .catch((error) => {
          this.$toast.error(error?.response?.data?.message);
        });
    },
    intiateExportRequests() {
      http
        .post(`${endpoints.EXPORT_PAYOUT_REQUESTS}`)
        .then(() => {
          this.$toast.success("Payout request export initiated");
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    fetchPendingRequests() {
      http
        .get(
          endpoints.VIEW_ALL_PAYOUT_REQUESTS +
            `?datatable=1&include=user,recommender,manager,payoutRequestSubCategory,bankAccount`
        )
        .then((response) => {
          let totalPending = response.data.reduce(function (total, num) {
            return total + num.amount;
          }, 0);

          this.totalPending = this.formatMoney(totalPending, "NGN");
        });
    },
  },
};
</script>
